/* eslint-disable no-restricted-globals */
/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-empty */
/* eslint-disable no-useless-computed-key */
/* eslint-disable no-case-declarations */
import { formatCurrencyForDisplay, BrowserNotification } from '@/utils';
import {
  useApolloClient,
  useMutation,
  useSubscription,
} from '@apollo/react-hooks';
import { reports } from '@/graphql/queries';
import {
  agentNotificationSubscription,
  networkNotificationSubscription,
  // liveTransferNotificationSubscription,
  globalNotificationSubscription,
  creditNotificationSubscription,
  reportNotificationSubscription,
  // liveTransferCallInfoNotification,
} from '@/graphql/subscriptions';
import { useUser, useSideLineExtension } from '@/hooks';
import { ReportFragment } from '@/graphql/fragments';
import {
  LoadingComponent,
  Notification,
  LiveTransferNotification,
  TransferProfile,
} from '@/components';
import { uuid } from 'uuidv4';
import { Container } from 'reactstrap';
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getImageUrl } from '@/utils/get-host';
import { updateCallInfoMutation } from '@/graphql/mutations';

type CreditData = {
  amount: number;
  initiatorContext: any;
  newCredit: number;
  type: string;
  requestType: string;
};

type ReportData = {
  url: string;
  fileName: string;
  type: string;
  id: string;
  message: string;
  cleanName: string;
  creationDate: number;
  status: string;
};

enum NotificationActionsEnum {
  transfer_notification = 'TRANSFER_NOTIFICATION',
  transfer_info_notification = 'TRANSFER_INFO_NOTIFICATION',
  delete = 'DELETE',
  delete_all = 'DELETE_ALL',
  delete_many = 'DELETE_MANY',
  other = 'OTHER',
  message = 'MESSAGE',
}

export interface Notification {
  color: string;
  action: NotificationActionsEnum;
  dismiss: () => void;
  type: string;
  id: string;
  timeout?: number;
  message: string;
  browserNotification?: globalThis.Notification;
}

interface LiveTransferNotification extends Notification {
  liveTransferData: {
    tierID: string;
    leadID: string;
    phone: string;
    campaignID: string;
    brokerID: string;
    spendingPeriodID: string;
    spendingPeriodQueueID: string;
    callSid: string;
  };
}

export interface TabNotification {
  report: boolean;
}

type createFnParams = {
  message: string;
  action: NotificationActionsEnum;
  timeout?: number;
  color: string;
  id?: string;
  type: string;
};
export interface NotificationContextType {
  create: (params: createFnParams) => void;
  notifications: Notification[];
  tabNotification: TabNotification;
  newLeadCount: number;
  newBulkCount: number;
  setTabNotification: (obj: TabNotification) => void;
}

export const NotificationContext = createContext<NotificationContextType>({
  create: () => {},
  notifications: [],
  tabNotification: {
    report: false,
  },
  newLeadCount: 1,
  newBulkCount: 1,
  setTabNotification: () => {},
});

const NotificationProvider: React.FunctionComponent = ({ children }) => {
  const { user, loading, firstTimeSetup } = useUser();
  const { sendSidelineMessage } = useSideLineExtension();

  const [newLeadCount, setNewLeadCount] = useState(1);
  const [newBulkCount, setNewBulkCount] = useState(1);
  const client = useApolloClient();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [showLongSync, setShowLongSync] = useState(false);

  const [updateCall] = useMutation(updateCallInfoMutation);

  const [tabNotification, setTabNotification] = useState<TabNotification>({
    report: false,
  });

  const { sidelineCampaigns = [] } = user || {};
  console.log(user);
  const deleteNotification = (id: string): void => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((n) => {
        if (n.id !== id) {
          if (n.browserNotification) {
            n.browserNotification.close();
          }
          return true;
        }

        return false;
      })
    );
  };
  const deleteManyNotification = (ids: string[]): void => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((n) => ids.some((id) => n.id !== id))
    );
  };

  const create = useCallback(
    (params: createFnParams): void => {
      const id = params.id ? params.id : uuid();
      let browserNotification: globalThis.Notification;

      if (params.type === NotificationActionsEnum.transfer_notification) {
        if (
          BrowserNotification &&
          BrowserNotification.permission === 'granted'
        ) {
          browserNotification = new BrowserNotification('Live Transfer', {
            body: `$${((params as any).liveTransferData.price / 100).toFixed(
              2
            )} ${(params as any).liveTransferData.tierName}`,
            icon: getImageUrl('favicon.ico'),
          });

          if (browserNotification) {
            browserNotification.addEventListener('click', () => {
              if (window) window.focus();
              if (parent) parent.focus();
              updateCall({
                variables: {
                  input: {
                    action: 'answer',
                    data: {
                      ...(params as any).liveTransferData,
                    },
                  },
                },
              });
              browserNotification.close();
            });
          }
        } else if (BrowserNotification) {
          BrowserNotification.requestPermission();
        }
      }
      setNotifications(
        notifications.concat([
          {
            ...params,
            id,
            dismiss: deleteNotification.bind(null, id),
          },
        ])
      );
      setTimeout(() => {
        deleteNotification(id);
      }, params.timeout || 5000);
    },
    [notifications, setNotifications]
  );

  const updateCache = (
    Id: string,
    newCreditBalance: number,
    brokerID?: string
  ): void => {
    console.log(
      `id : ${Id} and newcredit: ${newCreditBalance} brokerID: ${brokerID}`
    );
    try {
      if (brokerID !== user.network.brokerID) {
        const otherBrokers = user.otherBrokerCredits.map((b) => {
          if (b.brokerID === brokerID) {
            return { ...b, credits: newCreditBalance };
          }
          return b;
        });
        client.cache.writeData({
          id: Id,
          data: {
            otherBrokerCredits: otherBrokers,
          },
        });
      } else {
        client.cache.writeData({
          id: Id,
          data: {
            totalCredit: newCreditBalance,
          },
        });
      }
    } catch {
      console.log('Failed to update cache');
    }
    client.queryManager.broadcastQueries();
  };

  const onSubscriptionData = ({
    subscriptionData: { data: subdata },
  }: any): any => {
    const { notification = {} } = subdata || {};

    const {
      action,
      notificationID = '',
      data = { message: {} },
      timeout,
    } = notification as {
      notificationID: string;
      data: any;
      timeout: number;
      action: string;
    };

    // Undefined check for payload.... Phone ranger sends in different format
    if (data?.message) {
      if (data.message?.requestType === 'CREDIT') {
        const {
          initiatorContext,
          amount,
          newCredit,
          type,
        } = data.message as CreditData;

        switch (type) {
          case 'MANAGED_CREDIT_CHANGE':
            updateCache(data.relayId, newCredit);
            return;
          case 'CREDIT_TRANSFER':
            if (amount !== 0) {
              if (amount > 0) {
                data.color = 'success';
                data.message = `${
                  initiatorContext.agentName
                } transferred ${formatCurrencyForDisplay(
                  amount
                )} to your balance.`;
              } else {
                data.color = 'warning';
                data.message = `${
                  initiatorContext.agentName
                }  withdrew ${formatCurrencyForDisplay(
                  -amount
                )} from your balance.`;
              }
              data.type = 'alert';
              updateCache(user.id, newCredit);
            }
            break;
          case 'LEAD_PURCHASE':
            if (amount >= 0) {
              data.message = `New ${
                initiatorContext.campaignName
              } lead purchased: ${
                initiatorContext.leadName
              } (${formatCurrencyForDisplay(amount)}).`;
              data.color = 'success';
              data.type = 'alert';
              console.log(initiatorContext);
              updateCache(user.id, newCredit, initiatorContext.brokerID);
              setNewLeadCount((i) => i + 1);
              console.log(initiatorContext);
              if (sidelineCampaigns) {
                if (sidelineCampaigns.includes(initiatorContext.campaignID)) {
                  sendSidelineMessage({
                    type: 'SIDELINE_MESSAGE',
                    data: { leadID: initiatorContext.leadID },
                  });
                }
              }
            }
            break;
          case 'CALL_QUALIFIER':
            if (amount >= 0) {
              data.message = `New Call Qualifier Lead`;
              data.color = 'success';
              data.type = 'alert';
              console.log(initiatorContext);
              updateCache(user.id, newCredit, initiatorContext.brokerID);
              setNewLeadCount((i) => i + 1);
              console.log(initiatorContext);
              if (sidelineCampaigns) {
                if (sidelineCampaigns.includes(initiatorContext.campaignID)) {
                  sendSidelineMessage({
                    type: 'SIDELINE_MESSAGE',
                    data: { leadID: initiatorContext.leadID },
                  });
                }
              }
            }
            break;
          case 'LIVE_TRANSFER_PURCHASE':
            if (amount >= 0) {
              data.message =
                initiatorContext.discount === 0
                  ? `You've been charged (${formatCurrencyForDisplay(
                      amount
                    )}) for a live transfer lead.`
                  : `You've been charged the data only price of ${formatCurrencyForDisplay(
                      amount
                    )}.`;
              data.color = 'success';
              data.type = 'alert';
              updateCache(user.id, newCredit, initiatorContext.brokerID);
              setNewLeadCount((i) => i + 1);
            }
            break;
          case 'PENDING_LT_PURCHASE':
            if (amount >= 0) {
              data.message = `You will be charged ${formatCurrencyForDisplay(
                amount
              )} at the end of your call`;
              data.color = 'success';
              data.type = 'alert';
              updateCache(user.id, newCredit, initiatorContext.brokerID);
              setNewLeadCount((i) => i + 1);
            }
            break;
          case 'AUTO_CREDIT':
            data.message = `${formatCurrencyForDisplay(
              amount
            )} was added to your account based on your automatic funding rules.`;
            data.type = 'alert';
            data.color = 'success';
            updateCache(user.id, newCredit, initiatorContext.brokerID);
            break;
          case 'MANUAL_CREDIT':
            if (amount !== 0) {
              data.message = `${formatCurrencyForDisplay(
                amount
              )} was added to your account.`;
              data.type = 'alert';
              data.color = 'success';
              updateCache(user.id, newCredit, initiatorContext.brokerID);
            }
            break;
          case 'SELF_TRANSFER':
            console.info('Unrecognized credit payload format', data.message);
            return;
          case 'BULK_AGED_LEAD_PURCHASE':
            setNewBulkCount((i) => i + 1);
            if (amount > 0) {
              data.message = `Bulk Order Completed, Amount Charged: ${formatCurrencyForDisplay(
                amount
              )}`;
              data.color = 'success';
              data.type = 'alert';
              if (newCredit) {
                updateCache(user.id, newCredit, user.network.brokerID);
              }
            } else if (amount === 0) {
              data.message = 'Bulk Order Failed to Process';
              data.color = 'danger';
              data.type = 'alert';
            }
            break;
          default:
            console.log(
              `${type} type hasn't been set up for notifications yet.`
            );
            return;
        }
      }

      if (data.message?.requestType === 'REPORT') {
        const {
          status,
          fileName,
          url,
          id,
          creationDate,
          cleanName,
          message,
          type,
        } = data.message as ReportData;

        switch (type) {
          case 'homeOfficeCPA':
          case 'vendorRefunds':
          case 'lead':
            if (status === 'GENERATING') {
              try {
                const { reports: _reports } =
                  client.cache.readQuery({
                    query: reports,
                  }) || ({} as any);

                if (_reports) {
                  client.cache.writeData({
                    data: {
                      reports: [
                        {
                          status,
                          fileName,
                          url,
                          id,
                          creationDate,
                          cleanName,
                          ['__typename']: 'Report',
                        },
                        ..._reports,
                      ],
                    },
                  });
                }
              } catch {
                client.cache.writeData({
                  data: {
                    reports: [
                      {
                        status,
                        fileName,
                        url,
                        id,
                        creationDate,
                        cleanName,
                        ['__typename']: 'Report',
                      },
                    ],
                  },
                });
              }
              data.message = `Currently generating ${type} report.`;
              data.color = 'success';
              data.type = 'alert';
              break;
            }

            data.message = message;
            data.color = 'success';
            data.type = 'alert';

            setTabNotification({
              ...tabNotification,
              report: true,
            });
            break;
          case 'failure':
            data.message = message;
            data.color = 'danger';
            data.type = 'alert';
            break;
          default:
            console.log(
              `${type} type hasn't been set up for notifications yet.`
            );
            return;
        }

        try {
          const exists = client.cache.readFragment({
            id,
            fragment: ReportFragment,
          });

          if (exists) {
            client.cache.writeData({
              id,
              data: {
                status,
              },
            });
          }
        } catch {
          console.log('Failed to update cache.');
        }
        client.queryManager.broadcastQueries();
      }

      if (data.message?.requestType === 'AUTO_FUND') {
        const { type } = data.message;

        switch (type) {
          case 'AUTO_FUND_LEAD_CONTRIBUTION_FAILED':
            data.message = `Lead Weekly Buy In Mondays Charge Failed`;
            data.color = 'danger';
            data.type = 'alert';
            break;
          default:
            console.log(
              `${type} type hasn't been set up for notifications yet.`
            );
            return;
        }
      }
    }

    if (data) {
      switch (action) {
        case NotificationActionsEnum.delete:
          deleteNotification(data.notificationID);
          break;
        case NotificationActionsEnum.delete_all:
          setNotifications([]);
          break;
        case NotificationActionsEnum.delete_many:
          deleteManyNotification(data.notificationIDs || []);
          break;
        default:
          create({ ...data, id: notificationID, timeout, action });
      }
    }
  };

  useSubscription<{
    notification: Notification;
  }>(agentNotificationSubscription, {
    onSubscriptionData,
  });

  useSubscription<{
    notification: Notification;
  }>(networkNotificationSubscription, {
    onSubscriptionData,
  });
  useSubscription<{
    notification: Notification;
  }>(globalNotificationSubscription, {
    onSubscriptionData,
  });
  useSubscription<{
    notification: Notification;
  }>(creditNotificationSubscription, {
    onSubscriptionData,
  });
  useSubscription<{
    notification: Notification;
  }>(reportNotificationSubscription, {
    onSubscriptionData,
  });

  useEffect(() => {
    if (loading) {
      const tmId = setTimeout(() => {
        setShowLongSync(true);
      }, 5000);

      return () => {
        clearTimeout(tmId);
      };
    }
    setShowLongSync(false);
    return () => {};
  }, [loading]);

  if (firstTimeSetup) {
    return (
      <Container className="d-flex align-items-center flex-column mt-5">
        <h2>Performing First Time Setup</h2>
        <LoadingComponent />
      </Container>
    );
  }
  return (
    <NotificationContext.Provider
      value={{
        create,
        notifications,
        tabNotification,
        setTabNotification,
        newLeadCount,
        newBulkCount,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 82,
          left: 16,
          width: '100%',
          zIndex: 1051, // Any lower (even 1050) and the notifications will be hidden under modals
        }}
      >
        {notifications.map(({ action, id, type, ...rest }) => {
          switch (action) {
            case NotificationActionsEnum.other:
              switch (type) {
                case NotificationActionsEnum.transfer_info_notification:
                  return (
                    <TransferProfile
                      key={id}
                      {...(rest as any)}
                      dismiss={() => {
                        deleteNotification(id);
                      }}
                    />
                  );
                case NotificationActionsEnum.transfer_notification:
                  return (
                    <LiveTransferNotification key={id} {...(rest as any)} />
                  );
                default:
                  return null;
              }

            case NotificationActionsEnum.message:
            default:
              return <Notification key={id} {...rest} id={id} />;
          }
        })}
      </div>
      {loading && !user ? (
        <Container className="h-100 d-flex justify-content-center align-items-center">
          <LoadingComponent long={showLongSync} />
        </Container>
      ) : (
        children
      )}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
